

const Room = () => {

    // JASON
    return (
        <div className="Room Sketch" style={{backgroundColor: "black"}}>
            <iframe 
            src="https://publicaccessmemories.s3.amazonaws.com/index.html" 
            width={"100%"}
            height={"100%"}
            />
        </div>
    )
};

export default Room;