
import React from 'react';

const Room = () => {

    return (
        <div className="Room Sketch">
            <iframe
                src={"https://christinas.world/"}
                width={"100%"}
                height={"100%"}
            />
        </div>
    )
};

export default Room;
